@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

a {
  color: #36c4ce;
  text-decoration: none;
}

.input-small-top {
  font-size: 10px;
  margin-top: -25px;
  margin-bottom: 20px;
}

.rt-td {
  overflow: unset;
}

.ck-content {
  min-height: 300px;
}

.link {
  color: #36c4ce;
  cursor: pointer;
}

.ml-2 {
  margin-left: 20px;
}

.DateRangePickerInput {
  background-color: #36c4ce;
  border-radius: 10px;
  overflow: hidden;
  border: none;
}

.DateRangePickerInput_arrow_svg {
  fill: #ffffff;
}

.DateInput_input {
  font-size: 13px;
  font-weight: 500;
  padding: 8px;
  line-height: 15px;
  background-color: #36c4ce;
  color: white;
  text-align: center;
  padding-top: 12px;
}

.settings_gear {
  cursor: pointer;
  transition: 1s ease;
  transform: rotateZ(0deg);
  transform-origin: center;
  display: block;
  color: #49b26d;
}
.settings_gear:hover {
  transform: rotateZ(180deg);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heading-main {
  font-size: 18px;
  color: #36c4ce;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 10px;
}

.heading-content {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #666;
}

.btn-more {
  text-align: center;
  color: #b3b3b3;
  transition: 0.3s ease;
  margin-top: 20px;
}
.btn-more:hover {
  color: #36c4ce;
  cursor: pointer;
}

* {
  width: unset;
}

a,
a:hover {
  color: unset;
}

h1,
h3 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #666666;
}

.flex {
  display: flex;
}

.flew-wrap {
  flex-wrap: wrap;
}

.align-c {
  align-items: center;
}

.status-badge {
  color: #ffffff;
  background-color: #ee7511;
  padding: 0 10px;
  border-radius: 20px;
}
.status-badge.approved {
  background-color: #49b26d;
}
.status-badge.failed {
  background-color: #da4e6a;
}

.justify-between {
  justify-content: space-between;
}

.c-pointer {
  cursor: pointer;
}

.color-sec {
  color: #023054;
}

.color-pri {
  color: #36c4ce;
}

.max-width-1400 {
  max-width: 1400px;
  margin: 0 auto;
}

.max-width-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.content-container {
  padding-top: 50px;
}
.content-container .content-head {
  margin-bottom: 30px;
}

.content-card {
  background: white;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 30px;
}
.content-card .card-header,
.content-card .card-content {
  padding: 20px;
  box-sizing: border-box;
}
.content-card .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.content-card .card-header .header-type {
  padding: 10px 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  color: #022d4e;
  font-weight: 500;
}

.padding-20 {
  padding: 20px;
}

.padding-10 {
  padding: 10px;
}

.padding-h-20 {
  padding: 0 20px;
}

.padding-l-20 {
  padding-left: 20px;
}

.padding-0 {
  padding: 0 !important;
}
.padding-0 > * {
  padding: 0 !important;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}

.text-container {
  width: 100%;
  max-width: 500px;
}
.text-container .counter {
  float: right;
}

.flex-1 {
  flex: 1;
}

.max-width-800 {
  max-width: 800px;
}

.max-width-700 {
  max-width: 700px;
}

.max-width-600 {
  max-width: 600px;
}

.max-width-500 {
  max-width: 500px;
}

.max-width-400 {
  max-width: 400px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-r-20 {
  margin-right: 20px;
}

.info {
  font-size: 12px;
  color: #999999;
}

.float-r {
  float: right;
}

.v-tab {
  width: 200px;
  margin-right: 20px;
  border-right: 1px solid #36c4ce;
  background-color: #fafafa;
}
.v-tab li {
  list-style: none;
  font-size: 12px;
  padding: 10px 20px;
  cursor: pointer;
}
.v-tab li.active {
  background-color: #36c4ce;
  color: #ffffff;
}

.float-r {
  float: right;
}

.content-heading {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
}

.minmal {
  width: 750px;
}
.minmal .btn-main {
  float: right;
  margin-top: 20px;
  margin-right: 40px;
}

.grid-4 {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
}

.grid-3 {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.grid-2 {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-2 {
    grid-template-columns: 1fr;
  }
}

.grid-auto {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2em;
}

.clear {
  clear: both;
}

.hide {
  display: none !important;
}

.productCardInfo {
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.productCardInfo .title {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
  opacity: 0.7;
}
.productCardInfo .mainFig {
  font-size: 25px;
}
.productCardInfo .inner-content {
  text-align: center;
}

.category-container {
  margin-top: 20px;
}
.category-container .content-heading {
  font-weight: 500;
  font-size: 20px;
  color: #b3b3b3;
}
.category-container .add-content {
  font-weight: 300;
  font-size: 15px;
  color: #36c4ce;
  margin-left: 5px;
}
.category-container .cat-input {
  width: 100%;
  margin-left: 20px;
  max-width: 530px;
}
.category-container .service-item {
  flex: 1;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 250px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .service-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .service-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
}
.category-container .service-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .service-item .content-box svg {
  width: 60px !important;
}
.category-container .service-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .service-item .content-box h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-transform: capitalize;
  text-align: center;
}
.category-container .service-item ul {
  padding: 20px;
  color: gray;
}
.category-container .service-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .service-item ul li:last-child {
  border-bottom: none;
}
.category-container .product-item {
  flex: 1;
  margin-right: 2em;
  margin-bottom: 2em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 300px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .product-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .product-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
  background-color: rgba(16, 16, 16, 0.02);
  position: relative;
}
.category-container .product-item .content-box button {
  position: absolute;
  top: 10px;
  right: 0;
  outline: none;
}
.category-container .product-item .content-box button ul {
  padding: 0 !important;
}
.category-container .product-item .content-box button ul span {
  font-size: 12px;
  color: black !important;
}
.category-container .product-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .product-item .content-box svg {
  width: 60px !important;
}
.category-container .product-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .product-item .content {
  padding: 20px;
}
.category-container .product-item .content p {
  font-size: 12px;
  color: black;
}
.category-container .product-item .content .content-head {
  margin-bottom: 10px;
}
.category-container .product-item .content .content-head span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 10px;
  display: block;
}
.category-container .product-item .content .status {
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  background-color: #36c4ce;
}
.category-container .product-item ul {
  padding: 20px;
  color: gray;
}
.category-container .product-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .product-item ul li:last-child {
  border-bottom: none;
}

.nav-bar .search-input {
  border: none !important;
}
.nav-bar .search-input input::placeholder {
  color: #e6e6e6;
}
.nav-bar .search-drop {
  position: fixed;
  top: 100px;
  max-width: 1400px;
  width: 80%;
  right: 100px;
  z-index: 100;
  display: none;
  box-sizing: border-box;
}
.nav-bar .search-drop.show {
  display: block;
}
.nav-bar .nav-item-drop {
  font-size: 12px;
  color: #999999;
}
.nav-bar .nav-item-drop.danger {
  color: #da4e6a;
}
.nav-bar .bar {
  height: 30px;
  border-right: 1px solid #e6e6e6;
  margin: 0 20px;
}
.nav-bar .user-profile {
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 20px;
}
.nav-bar .user-profile .user-label {
  font-weight: 600;
  margin-right: 20px;
}
.nav-bar .user-profile .image-con {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #e6e6e6;
  cursor: pointer;
}
.nav-bar .user-profile .image-con img {
  width: 100%;
  height: 100%;
}

.react-table {
  border: none;
}
.react-table .rt-thead.-header {
  box-shadow: none;
}
.react-table .rt-thead .rt-td,
.react-table .rt-thead .rt-th,
.react-table .rt-tbody .rt-td {
  border-right: none !important;
}
.react-table .rt-tbody .rt-td {
  padding: 0 10px;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.react-table .rt-thead .rt-th {
  padding: 20px 10px;
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #36c4ce;
  text-align: left;
}
.react-table .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #f7f7f7;
  transition: 0.3s ease;
}
.react-table .rt-tbody .rt-tr-group:hover {
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(16, 16, 16, 0.02);
}
.react-table .-pagination {
  box-shadow: none;
}

.account-tabs {
  position: relative;
  display: flex;
  border-bottom: 1px solid #e6e6e6;
}
.account-tabs li {
  list-style: none;
  padding: 20px;
  position: relative;
  cursor: pointer;
}
.account-tabs li.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 2px;
  background-color: #36c4ce;
  transform: translateY(75%);
}

.goBack {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.card-control {
  padding: 20px;
}
.card-control .account-inner-tab {
  background-color: #fafafa;
  height: 100%;
  margin-left: -20px;
  border-right: 2px solid #36c4ce;
  margin-right: 20px;
  padding-bottom: 40px;
}
.card-control .account-inner-tab li {
  font-size: 14px;
  margin: 0;
  padding: 10px;
  color: #666666;
  font-weight: 500;
}
.card-control .account-inner-tab li small {
  font-size: 10px;
  display: block;
}
.card-control .account-inner-tab li.active {
  background-color: #36c4ce;
  color: #ffffff;
}
.card-control .account-inner-tab li.active > * {
  color: #ffffff;
}
.card-control .airtime-card {
  padding: 20px;
  border: 1px solid #e6e6e6;
  margin-bottom: 20px;
  position: relative;
}
.card-control .airtime-card .close {
  background: transparent;
  position: absolute;
  right: 5px;
  top: 5px;
}
.card-control .select-control ul > * {
  margin: 0 !important;
}
.card-control .item {
  flex: 1;
}
.card-control .item .item-head {
  font-weight: 300;
  font-size: 15px;
  color: #36c4ce;
  margin-bottom: 10px;
  margin-top: 20px;
}
.card-control .item .image-con {
  width: 150px;
  height: 150px;
  background-color: #e6e6e6;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card-control ul li {
  font-weight: 300;
  font-size: 13px;
  color: #36c4ce;
  margin-bottom: 10px;
  margin-top: 20px;
  cursor: pointer;
}
.card-control ul li span {
  margin-right: 10px;
}
.card-control .code-item .header {
  font-weight: 300;
  font-size: 13px;
  color: #36c4ce;
  margin-bottom: 10px;
  margin-top: 20px;
}
.card-control .code-item li {
  list-style: none;
  display: inline-block;
  padding: 5px 30px;
  background-color: #cccccc;
  margin-right: 10px;
  color: #ffffff;
}

.campTable {
  width: 100%;
}
.campTable td,
.campTable th {
  padding: 20px 20px;
}
.campTable th {
  width: 200px;
}
.campTable tr {
  border-bottom: 1px solid #f2f2f2;
}
.campTable tr:last-child {
  border-bottom: none;
}

.category-container {
  margin-top: 20px;
}
.category-container .content-heading {
  font-weight: 500;
  font-size: 20px;
  color: #b3b3b3;
}
.category-container .add-content {
  font-weight: 300;
  font-size: 15px;
  color: #36c4ce;
  margin-left: 5px;
}
.category-container .cat-input {
  width: 100%;
  margin-left: 20px;
  max-width: 530px;
}
.category-container .service-item {
  flex: 1;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 250px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .service-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .service-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
}
.category-container .service-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .service-item .content-box svg {
  width: 60px !important;
}
.category-container .service-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .service-item .content-box h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-transform: capitalize;
  text-align: center;
}
.category-container .service-item ul {
  padding: 20px;
  color: gray;
}
.category-container .service-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .service-item ul li:last-child {
  border-bottom: none;
}
.category-container .product-item {
  flex: 1;
  margin-right: 2em;
  margin-bottom: 2em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 300px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .product-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .product-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
  background-color: rgba(16, 16, 16, 0.02);
  position: relative;
}
.category-container .product-item .content-box button {
  position: absolute;
  top: 10px;
  right: 0;
  outline: none;
}
.category-container .product-item .content-box button ul {
  padding: 0 !important;
}
.category-container .product-item .content-box button ul span {
  font-size: 12px;
  color: black !important;
}
.category-container .product-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .product-item .content-box svg {
  width: 60px !important;
}
.category-container .product-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .product-item .content {
  padding: 20px;
}
.category-container .product-item .content p {
  font-size: 12px;
  color: black;
}
.category-container .product-item .content .content-head {
  margin-bottom: 10px;
}
.category-container .product-item .content .content-head span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 10px;
  display: block;
}
.category-container .product-item .content .status {
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  background-color: #36c4ce;
}
.category-container .product-item ul {
  padding: 20px;
  color: gray;
}
.category-container .product-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .product-item ul li:last-child {
  border-bottom: none;
}

.invoice-main li {
  list-style: none;
  display: block;
  flex: 1;
  color: black;
}
.invoice-main .heading-content li {
  font-weight: bold;
}
.invoice-main .divider {
  border-color: black;
}

.finance-control .productCardInfo {
  height: 200px;
}

.card-btn {
  margin-bottom: 20px;
}
.card-btn button {
  padding: 5px 20px;
  border: 2px solid white;
  border-radius: 20px;
  color: white;
  font-weight: 500;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s ease;
  outline: none;
}
.card-btn button:hover {
  background-color: inherit;
  border-color: inherit;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.text-align-r {
  text-align: right;
}

.text-align-l {
  text-align: left;
}

.black-color {
  color: black;
  border-color: black;
}

.login-main {
  background-color: #f9feff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}
.login-main h3 {
  font-size: 16px;
  text-align: left !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}
.login-main form {
  width: 400px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.05);
}

.tab-heading {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  /* width */
}
.tab-heading::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.tab-heading li {
  list-style: none;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  font-weight: 500;
  border-top: 1.5px solid transparent;
  cursor: pointer;
  color: #b3b3b3;
}
.tab-heading li.active {
  color: #36c4ce;
  border-color: #36c4ce;
}

.tab-content .tab-item {
  display: none;
  padding: 20px;
}
.tab-content .tab-item.active {
  display: block;
}

.custom-flex {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5em;
  margin-top: 10px;
}

.create-comp {
  font-size: 12px;
  font-weight: 500;
  color: #36c4ce;
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
}
.create-comp span {
  margin-left: 5px;
}
.create-comp div:last-child {
  margin-top: 2px;
  margin-left: 5px;
}

.category-item {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  height: 200px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease;
  cursor: pointer;
  transform: scale(1);
}
.category-item:hover {
  transform: scale(1.1);
  border-color: #36c4ce;
}
.category-item .top {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-item .title {
  height: 50px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-item .title .text-inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-weight: bold;
}

.breadcrum {
  display: flex;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .breadcrum {
    margin-left: 10px;
  }
}
.breadcrum a,
.breadcrum li {
  list-style: none;
  display: block;
  font-size: 12px;
  color: #36c4ce !important;
  font-family: "Montserrat", sans-serif;
  padding: 0 15px;
  border-right: 1px solid #e6e6e6;
  cursor: pointer;
}
.breadcrum a:first-child,
.breadcrum li:first-child {
  padding-left: 0;
}
.breadcrum a:last-child,
.breadcrum li:last-child {
  border-right: none;
}
.breadcrum a.active,
.breadcrum li.active {
  color: #999999 !important;
  cursor: not-allowed;
}

.gray-text > * {
  color: #999999 !important;
}

.create-category-top {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.create-category-top .flex-right,
.create-category-top .flex-left {
  flex: 1;
}
.create-category-top .upload-title {
  font-size: 12px;
  color: #36c4ce;
  text-align: center;
  margin-top: 10px;
}
.create-category-top .flex-right {
  margin: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.category-view-main .flex-right,
.category-view-main .flex-left {
  min-width: 300px;
}
.category-view-main .flex-left td,
.category-view-main .flex-left th {
  padding: 10px 0;
  font-size: 13px;
}
.category-view-main .flex-left th {
  width: 100px;
  font-weight: 500;
}
.category-view-main .flex-right ul {
  margin-top: 20px;
}
.category-view-main .flex-right ul li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #b3b3b3;
  box-shadow: 0 5px 5px rgba(16, 16, 16, 0.02);
  transition: 0.3s ease;
  transform: scale(1);
}
.category-view-main .flex-right ul li:hover {
  transform: scale(1.05);
  color: #36c4ce;
}

.img-con {
  max-width: 40px;
  height: 40px;
  background-color: #e6e6e6;
  border-radius: 50%;
  overflow: hidden;
}
.img-con img {
  width: 100%;
  height: 100%;
}

.image-con-large {
  min-width: 100px !important;
  height: 100px !important;
}

.image-con-large2 {
  min-width: 150px !important;
  height: 150px !important;
}

.user-info-table th,
.user-info-table td {
  padding: 20px;
}

.support-table th {
  min-width: 100px;
}
.support-table th,
.support-table td {
  padding: 5px 0;
  box-sizing: border-box;
}

.table-check {
  display: flex;
  align-items: center;
}
.table-check label {
  display: block !important;
  bottom: 10px;
  left: 30px;
}

.ticket-info {
  position: fixed;
  right: 0;
  width: 500px;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(16, 16, 16, 0.07);
  padding-top: 80px;
  overflow: hidden;
}
.ticket-info .close {
  position: absolute;
  top: 85px;
  right: 10px;
  color: #36c4ce;
  cursor: pointer;
}
.ticket-info .header-main {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  box-shadow: 0 5px 10px rgba(16, 16, 16, 0.07);
}
.ticket-info .header-main p {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13px;
}
.ticket-info .ticket-body {
  height: 100vh;
  padding: 20px;
  max-height: calc(100vh - 250px);
  overflow: auto;
  background-color: #f2f2f2;
  position: relative;
}
.ticket-info .ticket-body .replier,
.ticket-info .ticket-body .creator {
  max-width: 350px;
  margin-bottom: 20px;
}
.ticket-info .ticket-body .replier {
  margin-left: 110px;
}
.ticket-info .ticket-body .sender {
  border-top: 1px solid #f2f2f2;
  padding: 5px 10px;
  font-size: 13px;
}
.ticket-info .ticket-body .sender .user {
  color: #36c4ce;
}
.ticket-info .ticket-body .fileList {
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: #ffffff;
}
.ticket-info .ticket-body .fileList img {
  width: 50px;
  margin-right: 10px;
}
.ticket-info .ticket-body .fileList .fileListItem {
  position: relative;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px;
  box-sizing: border-box;
}
.ticket-info .ticket-body .fileList .fileListItem .close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #da4e6a;
}
.ticket-info .footer-main {
  display: flex;
}
.ticket-info .footer-main button {
  width: 100px;
  height: 120px;
}
.ticket-info .footer-main .textzone {
  flex: 1;
  position: relative;
}
.ticket-info .footer-main .textzone .file-upload {
  position: absolute;
  z-index: 2;
  bottom: 5px;
  right: 5px;
  color: #36c4ce;
  cursor: pointer;
}

.category-container {
  margin-top: 20px;
}
.category-container .content-heading {
  font-weight: 500;
  font-size: 20px;
  color: #b3b3b3;
}
.category-container .add-content {
  font-weight: 300;
  font-size: 15px;
  color: #36c4ce;
  margin-left: 5px;
}
.category-container .service-item {
  flex: 1;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 250px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .service-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .service-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
}
.category-container .service-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .service-item .content-box svg {
  width: 60px !important;
}
.category-container .service-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .service-item .content-box h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-transform: capitalize;
  text-align: center;
}
.category-container .service-item ul {
  padding: 20px;
  color: gray;
}
.category-container .service-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .service-item ul li:last-child {
  border-bottom: none;
}
.category-container .product-item {
  flex: 1;
  margin-right: 2em;
  margin-bottom: 2em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 300px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .product-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .product-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
  background-color: rgba(16, 16, 16, 0.02);
  position: relative;
}
.category-container .product-item .content-box button {
  position: absolute;
  top: 10px;
  right: 0;
  outline: none;
}
.category-container .product-item .content-box button ul {
  padding: 0 !important;
}
.category-container .product-item .content-box button ul span {
  font-size: 12px;
  color: black !important;
}
.category-container .product-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .product-item .content-box svg {
  width: 60px !important;
}
.category-container .product-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .product-item .content {
  padding: 20px;
}
.category-container .product-item .content p {
  font-size: 12px;
  color: black;
}
.category-container .product-item .content .content-head {
  margin-bottom: 10px;
}
.category-container .product-item .content .content-head span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 10px;
  display: block;
}
.category-container .product-item .content .status {
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  background-color: #36c4ce;
}
.category-container .product-item ul {
  padding: 20px;
  color: gray;
}
.category-container .product-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .product-item ul li:last-child {
  border-bottom: none;
}

.layout-main {
  background-color: #f9feff;
  width: 100%;
  height: 100vh;
  position: relative;
}
.layout-main .side-bar {
  position: fixed;
  width: 200px;
  background-color: #023054;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
}
.layout-main .side-bar .brand {
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  color: white;
  font-weight: 500;
  background: #022d4e;
}
.layout-main .side-bar ul {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  height: calc(100vh - 100px);
  padding-bottom: 20px;
  /* width */
  /* Handle */
}
.layout-main .side-bar ul::-webkit-scrollbar {
  width: 3px;
}
.layout-main .side-bar ul::-webkit-scrollbar-thumb {
  background: #034c86;
  border-radius: 10px;
}
.layout-main .side-bar ul a,
.logout {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: left;
  font-size: 13px;
  color: gray;
  padding: 30px 10px;
  padding-left: 30px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease;
}
.layout-main .side-bar ul a .side-icon {
  width: 50px;
}
.layout-main .side-bar ul a svg {
  margin-left: 5px;
}
.layout-main .side-bar ul a svg path {
  fill: gray;
}
.layout-main .side-bar ul a.active {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-main .side-bar ul a.active svg path {
  fill: #ffffff;
}
.layout-main .side-bar .down-indicator {
  text-align: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  color: white;
  background-color: inherit;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.layout-main .nav-bar {
  position: fixed;
  width: calc(100% - 200px);
  height: 80px;
  background-color: #ffffff;
  left: 200px;
  top: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}
.layout-main .nav-bar .nav-right {
  height: 100%;
  display: flex;
}
.layout-main .nav-bar .nav-right li {
  width: unset;
  display: flex;
  cursor: pointer;
  list-style: none;
  padding: 0 20px;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  color: #999999;
  border-bottom: 2px solid transparent;
}
.layout-main .nav-bar .nav-right li.active {
  color: #36c4ce;
  border-color: #36c4ce;
}
.layout-main .nav-bar .nav-left {
  height: 100%;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  margin-right: 40px;
  display: flex;
  justify-content: flex-end;
}
.layout-main .nav-bar .nav-left select {
  width: unset;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
  height: 30px;
  font-size: 16px;
  color: #6b6b6b;
  border: none;
  outline: none;
}
.layout-main .content-main {
  position: absolute;
  width: calc(100% - 200px);
  left: 200px;
  top: 80px;
  padding: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.layout-main .content-main.no-header {
  top: 0;
  height: 100vh;
}

.create-comp {
  min-width: 150px;
}

/*# sourceMappingURL=default.css.map */
